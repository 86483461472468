<template>
  <div class="container orders-container">
    <div class="row header">
      <div class="col">
        <div class="title font-size-xl font-weight-bold">
          {{ $t("orders.title") }}
          <span class="color-twiks ps-2" v-if="orders_meta !== null"> {{ orders_meta.total }} </span>
        </div>
      </div>
      <div class="col-auto Aligner">
        <order-filters v-model="filters"/>
      </div>
    </div>

    <div class="body">

      <CartGroupLayout v-if='orders === null' :shop="{}" :products="[]"/>

      <!--   Ordered Group   -->
      <template v-if='orders !== null'>
        <order v-for="order in orders" :order="order" :key="order.id"
               @shop_rated="shop_rated($event)"
               @driver_rated="driver_rated($event)"
               @status_updated="status_updated(order.id, $event)"
        />
      </template>

      <loading :loading="loading" />

    </div>
  </div>
</template>

<script>
import {Promise} from "core-js";
import CartGroupLayout from "@/views/Cart/partials/CartGroupLayout";
import OrderFilters from "@/views/Orders/partials/OrderFilters";
import Order from "@/views/Orders/partials/Order";
import Loading from "@/components/Loading";
import {mapActions} from "vuex";
import {useI18n} from "vue-i18n";
import {useMeta} from "vue-meta";

export default {
  name: "OrdersIndex",
  components: {Loading, Order, OrderFilters, CartGroupLayout},
  setup(){
    const {t} = useI18n() // use as global scope
    useMeta({
      title: t("orders.page_title"),
    })
  },
  data() {
    return {
      filters: {
        filter_by_days: null,
        orderBy: 'desc',
        filter: 'active',
        status: null,
        per_page: 10
      },
      data_ready: false,
      orders: null,
      orders_meta: null,
      //Loading
      loading: false,
    }
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getOrdersByFilters();
      }
    }
  },
  methods: {
    ...mapActions(['setErrors']),
    shop_rated(response) {
      //Find all shops with this id, and modify mu_rate value
      this.orders.map(order => {
        if (order.shop.id === response.shop_id) {
          order.shop.my_rating = {rating: response.rating.rating, description: response.rating.comment}
        }
      })
    },
    driver_rated(response) {
      //Find all shops with this id, and modify mu_rate value
      this.orders.map(order => {
        if (order.driver !== null) {
          if (order.driver.id === response.driver_id) {
            order.driver.my_rating = {rating: response.rating.rating, description: response.rating.comment}
          }
        }
      })
    },
    status_updated(order_id, response){
      this.orders.map(order => {
        if (order.id === order_id) {
          order.status = response;
        }
      })
    },
    async fetch_orders(filters) {
      return new Promise((resolve, reject) => {
        let url = window.Routes.getRoute("orders");
        window.axios.get(url, {
          params: filters
        }).then(
            (response) => {
              resolve(response.data);
            },
            (error) => {
              this.setErrors(error.response.data.errors);
              reject(error.response.data.errors);
            }
        );
      });
    },
    getOrdersByFilters() {
      this.data_ready = false;
      this.orders = null;
      this.fetch_orders(this.filters).then((response) => {
        this.data_ready = true;
        this.orders = response.data;
        this.orders_meta = response.meta;
        this.loading = false;
      });
    },
    loadMore() {
      if (this.loading === false) {
        if (
            this.data_ready === true &&
            this.orders_meta.current_page < this.orders_meta.last_page
        ) {
          if (
              window.innerHeight + window.scrollY >=
              document.body.scrollHeight
          ) {
            //DISPLAY LOADING BAR and disable scroll bottom event
            this.loading = true;
            //LOAD MORE PRODUCTS
            //Clone filters
            let filters = JSON.parse(JSON.stringify(this.filters));
            filters['page'] = this.orders_meta.current_page + 1;

            this.fetch_orders(filters).then((response) => {
              this.data_ready = true;
              this.orders = [...this.orders, ...response.data];
              this.orders_meta = response.meta;
              this.loading = false;
            });
          }
        }
      }
    },
  },
  created() {
    this.getOrdersByFilters();
  },
  mounted() {
    window.onscroll = () => {
      this.loadMore();
    };
  },
}
</script>

<style lang="scss" scoped>
.orders-container {
  .header {
    padding-top: map-get($spacings, "default");
    padding-bottom: map-get($spacings, "default");
  }
}

@include media-breakpoint-down(md) {
  .orders-container {
    padding-left: 0;
    padding-right: 0;

    .header {
      padding-left: map-get($spacings, "default");
      padding-right: map-get($spacings, "default");
      padding-top: 0;
    }
  }
}
</style>
