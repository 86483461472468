<template>
  <div class="row order-filters">
    <div class="col-6 col-md">
      <select class="form-select" @change="filter_by_status_changed($event)">
        <option value="">{{ $t("orders.all_statuses") }}</option>
        <option v-for="order_status in order_statuses" :key="order_status" :value="order_status">
          {{ $t("orders.status." + order_status) }}
        </option>
      </select>
    </div>
    <div class="col-6 col-md">
      <select class="form-select" @change="filter_by_days_changed($event)">
        <option value="">{{ $t("orders.by_time.all_time") }}</option>
        <option value="7">{{ $t("orders.by_time.last_week") }}</option>
        <option value="30">{{ $t("orders.by_time.last_month") }}</option>
        <option value="180">{{ $t("orders.by_time.last_6_months") }}</option>
        <option value="365">{{ $t("orders.by_time.last_year") }}</option>
      </select>
    </div>
    <div class="col-md-auto active_filter">
      <div class="btn-group" role="group">
        <button type="button" class="btn"
                :class="{'btn-primary' : filters.filter === 'active', 'btn-outlined' : filters.filter === 'past'}"
                @click="filters.filter = 'active'">
          {{ $t("orders.by_status_grouped.active") }}
        </button>

        <button type="button" class="btn"
                @click="filters.filter = 'past'"
                :class="{'btn-primary' : filters.filter === 'past', 'btn-outlined' : filters.filter === 'active'}">
          {{ $t("orders.by_status_grouped.past") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderFilters",
  props: {
    modelValue: {
      type: Object,
      default: () => {
        return {
          filter_by_days: null,
          orderBy: 'desc',
          filter: 'active',
          status: null,
        };
      }
    }
  },
  data() {
    return {
      filters: null,
      order_statuses: [
        'pending',
        'driver_search',
        'driver_not_found',
        'shop_awaiting_confirmation',
        'shop_declined',
        'shop_confirmed',
        'payment_pending',
        'payment_succeeded',
        'payment_failed',
        'shop_preparing',
        'shop_prepared',
        'shipped',
        'delivered',
        'accepted',
        'declined',
        'canceled',
      ]
    }
  },
  methods: {
    filter_by_status_changed(event) {
      let value = event.target.value;
      if (value.length === 0) {
        value = null;
      }
      this.filters.status = value;
    },
    filter_by_days_changed(event) {
      let value = parseInt(event.target.value);
      if (isNaN(value)) {
        value = null;
      }
      this.filters.filter_by_days = value;
    },
  },
  watch:{
    modelValue: {
      deep:true,
      immediate:true,
      handler(){
        this.filters = this.modelValue;
      }
    },
    filters: {
      deep:true,
      handler(){
        this.$emit('update:modelValue', this.filters)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @include media-breakpoint-down(md) {
    .order-filters {
      margin-top: map-get($spacings, "small");
      .active_filter {
        padding-top: map-get($spacings,"small");
        .btn-group {
          width:100%;
        }
      }
    }
  }
</style>
